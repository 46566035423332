import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown, CirclePlay, SearchIcon } from 'lucide-react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import Link from 'next/link';
import PlayMenu, { WellnessBanner } from './PlayMenu';
import MoreMenu from './MoreMenu';
import LanguageContext from '@lib/contexts/languageContext';
import { findParentElement } from '@lib/utils';

const SearchLink = styled(Link)``;

const Nav = styled.nav<{ isOpen?: boolean }>`
  flex: 1;
  position: absolute;
  left: 0;
  top: 68px;
  height: calc(100dvh - 68px);
  width: 100%;
  background-color: white;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 400ms ease;
  overflow-y: scroll;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    position: static;
    height: auto;
    width: auto;
    padding-top: 0;
    margin-left: 20px;
    overflow-y: unset;
    transform: none;
    margin-left: 20px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 10px 20px;
  margin: 0 0 20px;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    width: 100%;
    // max-width: 740px;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    padding: unset;
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.viewports.desktop}px) {
    gap: 24px;
  }
`;

const Topic = styled.li`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    flex-direction: row;
    height: 30px;
    padding: 15px 0;
    border-bottom: none;
  }
`;

const NavLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: #242424;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: capitalize;
  padding: 25px 0;
  text-decoration: none;
  cursor: pointer;
  transition: color 200ms ease-in;

  .lucide-chevron-down {
    margin: 0 0 0 auto;
    transition: transform 500ms ease-in;
    transform-origin: center center;
    transform: rotateX(0deg);
  }

  .Header--MenuDropdown ~ &.active {
    color: ${({ theme }) => theme.colors.primary['blue-1']};

    .lucide-chevron-down {
      transform: rotateX(180deg);
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    width: auto;
    font-size: 14px;
    padding: 0 5px;

    .lucide-chevron-down {
      margin: 0;
    }
  }
`;

const WellnessWrapper = styled.div`
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    display: none;
  }
`;

const Menu = ({ isOpen, onLinkClick }) => {
  const ref = useRef<HTMLDivElement>(null);
  const lang = useContext(LanguageContext);
  const [open, setOpen] = useState(null);

  const handleMenuClicks = (e) => {
    const clickedInMenu = ref.current.contains(e.target);
    const clickedAnchor = (
      e.target instanceof HTMLAnchorElement
        ? e.target
        : findParentElement(e.target, 'a')
    ) as HTMLAnchorElement;
    //
    if (
      !clickedInMenu ||
      (clickedAnchor && clickedAnchor?.dataset?.['link'] !== '#')
    ) {
      setOpen(null);
      onLinkClick();
    }
  };

  const handleClick = (e) => {
    const _open = open;
    const clickedInMenu = ref.current.contains(e.target);
    if (_open && !clickedInMenu) {
      setOpen(null);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClick);
    }
    //
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [open]);

  const items = useMemo(() => {
    return [
      {
        key: 'play',
        text: (
          <>
            <CirclePlay className="size-5 stroke-2 pr-1" />
            <Trans id="global_menu.play.title" />
          </>
        ),
        link: '#',
        menu: PlayMenu,
      },
      {
        key: 'live',
        text: (
          <>
            <Trans id="global_menu.live.title" />
          </>
        ),
        link: `/${lang}/live`,
      },
      {
        key: 'courses',
        text: (
          <>
            <Trans id="global_menu.course.title" />
          </>
        ),
        link: `/${lang}/courses`,
        // menu: CourseMenu,
      },
      {
        key: 'more',
        text: (
          <>
            <Trans id="global_menu.more.title" />
          </>
        ),
        link: '#',
        menu: MoreMenu,
      },
      {
        key: 'store',
        text: (
          <>
            <Trans id="global_menu.store.title" />
          </>
        ),
        link: 'https://store.yogobe.com',
      },
      ...(lang === 'se'
        ? [
            {
              key: 'wellness',
              text: <Trans id="global_menu.more.friskvårdsbidrag.title" />,
              link: `/se/friskvardsbidrag`,
            },
          ]
        : []),
    ];
  }, [lang]);

  return (
    <Nav ref={ref} isOpen={isOpen} onClick={handleMenuClicks}>
      <List>
        {items.map(({ menu: Menu, ...item }) => {
          const selected = open === item.key;
          return (
            <Topic key={item.key}>
              {Menu ? (
                <Menu
                  className={['Header--MenuDropdown'].join(' ')}
                  open={selected}
                />
              ) : null}
              <NavLink
                prefetch={false}
                href={item.link}
                {...(item.key === 'store' ? { target: '_blank' } : {})}
                className={selected ? 'active' : ''}
                onClick={(e) => {
                  if (Menu) {
                    e.preventDefault();
                    setOpen(!selected ? item.key : null);
                  }
                }}
                // need this in global click event to see if we should close menu
                data-link={item.link}
              >
                {item.text}
                {Menu ? (
                  <ChevronDown className="pl-1.5 size-6 lg:size-5" />
                ) : null}
              </NavLink>
            </Topic>
          );
        })}
        <SearchLink
          className="flex items-center gap-2 -order-1 border rounded py-3 px-4 lg:px-0 lg:-order-none lg:border-none"
          href={`/${lang}/search`}
        >
          <SearchIcon className="size-5 mt-0.5 stroke-3 stroke-black" />
          <span className="text-neutral-500 lg:text-dark font-normal lg:font-semibold">
            <Trans id="global_menu.search.title" />
            <span className="lg:hidden">...</span>
          </span>
        </SearchLink>
        <WellnessWrapper>
          <WellnessBanner />
        </WellnessWrapper>
      </List>
    </Nav>
  );
};

Menu.toString = Nav.toString;

export default Menu;
