import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Main } from '@components/layout/Layout';
import { IosBanner } from '@ecosystems/banner';
import Header from '@ecosystems/navigations/header/Header';
import VideosSubHeader from '@ecosystems/navigations/VideosSubHeader';
import Footer from '@organisms/Footer';
import { UserContext } from '@lib/contexts/UserProvider';
import TrialAvailableBanner from '@organisms/stripeBanners/TrialAvailableBanner';
import PaymentConfirmationModal from '@organisms/modals/paymentConfirmation/PaymentConfirmationModal';
import WellnessUpsellModal from '@organisms/modals/payment/WellnessUpsellModal';
import { EmailValidationEmail } from '@ecosystems/banner/emailValidation';
import { EndImpersonatorSession } from '@ecosystems/EndImpersonatorSession';
import { usePreference } from '@lib/hooks/usePreference';
import YearlyOffer from '@organisms/stripeBanners/YearlyOffer';

const VIDEO_SUBHEADER_ROUTES = [
  '/[lang]/videos/genres/[genre]',
  '/[lang]/program',
  '/[lang]/playlists',
  '/blog',
];

const Layout = (props) => {
  const router = useRouter();
  const [currentUser] = useContext(UserContext);
  const preferences = usePreference((state) => ({
    currency: state.currency,
    setCurrency: state.setCurrency,
  }));

  useEffect(() => {
    if (props.currency) {
      preferences.setCurrency(props.currency);
    }
  }, []);

  if (router.route === '/[lang]/maintenance') {
    return <Main>{props.children}</Main>;
  }

  return (
    <>
      <YearlyOffer />
      {currentUser?.impersonated ? <EndImpersonatorSession /> : null}
      <Header />
      <div>
        {VIDEO_SUBHEADER_ROUTES.includes(router.route) && <VideosSubHeader />}
        {currentUser?.id && !currentUser?.isEmailActivated ? (
          <EmailValidationEmail />
        ) : null}
        {currentUser?.id &&
        currentUser?.isEmailActivated &&
        currentUser?.trial ? (
          <TrialAvailableBanner />
        ) : null}
        {(router.route === `/video` || router.route === `/playlist`) && (
          <IosBanner />
        )}
        {router.query['order_id'] ? (
          <>
            <PaymentConfirmationModal
              open={true}
              orderId={router.query?.order_id as string}
            />
            <WellnessUpsellModal key="WellnessUpsellModal" />
          </>
        ) : null}
        <Main data-currency={preferences.currency}>{props.children}</Main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
